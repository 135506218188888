<template>
  <div>
    <div v-show="serviceDiv">
      <div class="second_return_icon" @click="returnButton()">
        <i class="iconfont icon-fanhui"></i>
        融资企业详情
      </div>
      <div class="flex_center_between_box companyDiv">
        <div class="work_business">
          <div class="flex_box work_top">
            <div class="work_top_div" v-show="logoPhoto">
              <img :src="img4">
            </div>
            <div v-if="percent.more">
              <div class="work_business_company">{{percent.cnCompany}}</div>
              <div class="work_business_company_en">{{percent.enCompany}}</div>
            </div>
            <div v-else>
              <div class="work_business_company work_business_company_div">{{percent.cnCompany}}</div>
            </div>
          </div>
          <ul class="work_business_detail">
            <li>
              <div class="flex_box">
                <div class="iconfont icon-xiaopangchezhengxinguanli"></div>
                <div class="work_business_detail_font over">{{percent.creditCore}}</div>
              </div>
              <div class="flex_box min_width">
                <div class="iconfont icon-dianhuatianchong"></div>
                <div class="work_business_detail_font overRight">{{percent.tel}}</div>
              </div>
            </li>
            <li>
              <div class="flex_box">
                <div class="iconfont icon-dizhi"></div>
                <div class="work_business_detail_font over" :title="percent.address">{{percent.address}}</div>
              </div>
              <div class="flex_box min_width">
                <div class="iconfont icon-youjian"></div>
                <div class="work_business_detail_font overRight">{{percent.email}}</div>
              </div>
            </li>
            <li>
              <div class="flex_box">
                <img :src="img" class="work_business_detail_icon">
                <div class="work_business_detail_font over">{{addressInfo}}</div>
              </div>
              <div class="flex_box min_width">
                <div class="iconfont icon-24gf-network"></div>
                <div class="work_business_detail_font overRight">{{percent.network}}</div>
              </div>
            </li>
          </ul>
          <!-- <div class="work_business_person button_show">个人中心</div> -->
        </div>
        <div class="work_chart">
          <div class="work_chart_item flex_center_between_box">
            <div ref="risk1" class="corporateRateLeftEcharts1" :id="myEcharts" />
            <div ref="risk2" class="corporateRateLeftEcharts1" />
            <div ref="risk3" class="corporateRateLeftEcharts1" />
          </div>
          <ul class="work_chart_detail flex_center_between_box">
            <li>
              <div class="work_chart_detail_color color1"></div>
              <div class="work_chart_detail_score color1">{{e_3Aecharts.creditRating}}</div>
              <div class="work_chart_detail_tit">信用评级</div>
            </li>
            <li>
              <div class="work_chart_detail_color color2"></div>
              <div class="work_chart_detail_score color2">{{e_3Aecharts.creditScore}}</div>
              <div class="work_chart_detail_tit">信用评分</div>
            </li>
            <li>
              <div class="work_chart_detail_color color3"></div>
              <div class="work_chart_detail_score color3">{{e_3Aecharts.vioIndex}}</div>
              <div class="work_chart_detail_tit">违约指数</div>
            </li>
            <li>
              <div class="work_chart_detail_color color4"></div>
              <div class="work_chart_detail_score color4">{{e_3Aecharts.payoffIndex}}</div>
              <div class="work_chart_detail_tit">偿还指数</div>
            </li>
            <li>
              <div class="work_chart_detail_color color5"></div>
              <div class="work_chart_detail_score color5">{{e_3Aecharts.creditRiskLevel}}</div>
              <div class="work_chart_detail_tit">信用风险等级</div>
            </li>
          </ul>
          <div class="button_show" @click="RiskPrediction()">风险预测</div>
        </div>
      </div>
      <div class="corporate_image">
        <ul class="corporate_image_div flex_center">
          <li class="">
            <div class="corporate_image_icon">
              <div class="iconfont icon-gongsi"></div>
            </div>
            <div class="corporate_image_edit">
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div class="corporate_image_tit">公司性质</div>
            <table class="table_line2">
              <tr>
                <td>公司性质</td>
                <td class="over2">{{Alldate.entclass}}</td>
              </tr>
              <tr>
                <td>成立年限</td>
                <td class="over2">{{Alldate.esdate}}</td>
              </tr>
              <tr>
                <td>注册资本</td>
                <td class="over2">{{Alldate.regcap}}</td>
              </tr>
              <tr>
                <td>实付资本</td>
                <td class="over2">{{Alldate.reccap}}</td>
              </tr>
            </table>
          </li>
          <li class="">
            <div class="corporate_image_icon">
              <div class="iconfont icon-tianjiagudongzhanghu01"></div>
            </div>
            <div class="corporate_image_edit">
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div class="corporate_image_tit">股东结构</div>
            <table>
              <tr>
                <td>自然人股东</td>
                <td class="over2">{{Alldate.sharehHolders}}</td>
                <td class="corporate_image_tr_td3">{{Alldate.shareHoldersPer}}</td>
              </tr>
              <tr>
                <td class="over1">公司法人股东</td>
                <td class="over2">{{Alldate.inShareHolders}}</td>
                <td class="corporate_image_tr_td3">{{Alldate.inShareHolders}}</td>
              </tr>
              <tr>
                <td>子公司</td>
                <td class="over2">{{Alldate.subEnterprise}}</td>
                <td class="corporate_image_tr_td3">{{Alldate.subEnterpriseFigure}}</td>
              </tr>
              <tr>
                <td>关联公司</td>
                <td class="over2">{{Alldate.relEnterprise}}</td>
                <td class="corporate_image_tr_td3">{{Alldate.reEnterpriseFigure}}</td>
              </tr>
            </table>

          </li>
          <li class="">
            <div class="corporate_image_icon">
              <div class="iconfont icon-shuishoucaiwu"></div>
            </div>
            <div class="corporate_image_edit">
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div class="corporate_image_tit">财务表现</div>
            <table>
              <tr>
                <td>销售额</td>
                <td class="over2">--</td>
                <td class=" flex_box corporate_image_tr_td3 up">
                  <!-- <div class="iconfont icon-web-icon-1 float_right"></div> -->
                  <div class="up float_right float_right">--</div>
                </td>
              </tr>
              <tr>
                <td>净利润</td>
                <td class="over2">--</td>
                <td class="flex_box corporate_image_tr_td3 down">
                  <!-- <div class="iconfont icon-web-icon- float_right"></div> -->
                  <div class="up float_right">--</div>
                </td>
              </tr>
              <tr>
                <td>资产总额</td>
                <td class="over2">--</td>
                <td class="flex_box corporate_image_tr_td3 up">
                  <!-- <div class="iconfont icon-web-icon-1 float_right"></div> -->
                  <div class="up float_right">--</div>
                </td>
              </tr>
              <tr>
                <td>负债总额</td>
                <td class="over2">--</td>
                <td class="flex_box corporate_image_tr_td3 up">
                  <!-- <div class="iconfont icon-web-icon-1 float_right"></div> -->
                  <div class="up float_right">--</div>
                </td>
              </tr>
            </table>
          </li>
          <li class="">
            <div class="corporate_image_icon">
              <div class="iconfont icon-jingying_mian"></div>
            </div>
            <div class="corporate_image_edit">
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div class="corporate_image_tit">经营现状</div>
            <table class="table_line2">
              <tr>
                <td>主营业务</td>
                <td class="over2">{{Alldate1.mainDescription}}</td>
              </tr>
              <tr>
                <td>主要产品</td>
                <td class="over2">{{Alldate1.mainProduct}}</td>
              </tr>
              <tr>
                <td>覆盖市场</td>
                <td class="over2">--</td>
              </tr>
              <tr>
                <td>员工人数</td>
                <td class="over2">{{Alldate1.staffNum}}</td>
              </tr>
            </table>

          </li>
          <li class="">
            <div class="corporate_image_icon">
              <div class="iconfont icon-gudongxinxi"></div>
            </div>
            <div class="corporate_image_edit">
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div class="corporate_image_tit">管理人员</div>
            <table class="table_line2">
              <tr v-for="(item,index) in Alldate.managerPerson" :key="index">
                <td>{{item.name}}</td>
                <td class="over2">{{item.position}}</td>
              </tr>
            </table>

          </li>
          <li class="">
            <div class="corporate_image_icon">
              <div class="iconfont icon-menu_flyz"></div>
            </div>
            <div class="corporate_image_edit">
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div class="corporate_image_tit">负面信息</div>
            <table class="table_line2">
              <tr>
                <td>失信名单</td>
                <td class="over2">{{Alldate2.fhshixinInfos}}</td>
              </tr>
              <tr>
                <td>执行公告</td>
                <td class="over2">{{Alldate2.fhzxggInfos}}</td>
              </tr>
              <tr>
                <td>裁判文书</td>
                <td class="over2">{{Alldate2.fhcpwsInfos}}</td>
              </tr>
              <tr>
                <td>开庭公告</td>
                <td class="over2">{{Alldate2.fhktggInfos}}</td>
              </tr>
            </table>

          </li>
        </ul>
      </div>
    </div>
    <div v-show="serviceDiv1" class="return_icon_class">
      <div class="second_return_icon1 margin_20">
        <i class="iconfont icon-fanhui" @click="returnButtonIcon()"></i>
      </div>
      <div class="second_title_box margin_B20">
        <span class="second_title1">
          风险预测
        </span>
      </div>
      <div class="risk_prediction">
        <div class="risk_prediction_tit">疑似空壳指数</div>
        <div class="flex_center_start_box">
          <div class="risk_prediction_left flex_center_col" id="left">
            <div ref="risk4" class="corporateRateLeftEcharts" />
            <div class="risk_prediction_left_tit">该公司：未见/轻微异常</div>
          </div>
          <div class="risk_prediction_right" id="right">
            <div class="risk_prediction_right_div">
              <div class="risk_prediction_right_div_top flex_center_between_start_box">
                <div class="risk_prediction_con_left">
                  <div class="risk_prediction_con_tit flex_center">
                    <div class="ztidax">注册信息异常</div>
                    <div class="risk_prediction_rate flex_wrap">
                      <div v-for="(item,index) in 5" :key="index" :class="address_ex>index?'iconfont icon-xingxing':'iconfont icon-xingxing1'"></div>
                    </div>
                  </div>
                  <div>
                    <div class="risk_prediction_con_con flex_center_between_box">
                      <div>联系电话:</div>
                      <div>{{contractPhone == 0 ? '未见异常' : '疑似异常'}}</div>
                    </div>
                    <div class="risk_prediction_con_con flex_center_between_box">
                      <div>邮箱:</div>
                      <div>{{emails == 0 ? '未见异常' : '疑似异常'}}</div>
                    </div>
                    <div class="risk_prediction_con_con flex_center_between_box">
                      <div>网站:</div>
                      <div>{{webstate == 0 ? '未见异常' : '疑似异常'}}</div>
                    </div>
                  </div>
                </div>
                <div class="risk_prediction_con_left">
                  <div class="risk_prediction_con_tit flex_center">
                    <div class="ztidax">经营异常</div>
                    <div class="risk_prediction_rate flex_wrap">
                      <div v-for="(item,index) in 5" :key="index" :class="management_ex>index?'iconfont icon-xingxing':'iconfont icon-xingxing1'"></div>
                    </div>
                  </div>
                  <div>
                    <div class="risk_prediction_con_con flex_center_between_box">
                      <div>营收记录:</div>
                      <div>{{revenueRecord == 0 ? '未见异常' : '疑似异常'}}</div>
                    </div>
                    <div class="risk_prediction_con_con flex_center_between_box">
                      <div>社保缴纳记录:</div>
                      <div>{{socialRecord == 0 ? '未见异常' : '疑似异常'}}</div>
                    </div>
                    <div class="risk_prediction_con_con flex_center_between_box">
                      <div>治理结构:</div>
                      <div>{{governanceStructure == 0 ? '未见异常' : '疑似异常'}}</div>
                    </div>
                    <div class="risk_prediction_con_con flex_center_between_box">
                      <div>经营异常:</div>
                      <div>{{management == 0 ? '未见异常' : '疑似异常'}}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="risk_prediction_right_div_bottom flex_center_between_start_box">
                <div class="risk_prediction_con_left">
                  <div class="risk_prediction_con_tit flex_center">
                    <div class="ztidax">人员异常</div>
                    <div class="risk_prediction_rate flex_wrap">
                      <div v-for="(item,index) in 5" :key="index" :class="personnel_ex>index?'iconfont icon-xingxing':'iconfont icon-xingxing1'"></div>
                    </div>
                  </div>
                  <div>
                    <div class="risk_prediction_con_con flex_center_between_box">
                      <div>股东异常:</div>
                      <div>{{shareholder == 0 ? '未见异常' : '疑似异常'}}</div>
                    </div>
                    <div class="risk_prediction_con_con flex_center_between_box">
                      <div>法定代表人异常:</div>
                      <div>{{legalRepresentative == 0 ? '未见异常' : '疑似异常'}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="risk_prediction">
        <div class="risk_prediction_tit">暴雷指数</div>
        <div class="flex_center_start_box">
          <div class="risk_prediction_left flex_center_col">
            <div ref="risk5" class="corporateRateLeftEcharts" />
            <div class="risk_prediction_left_tit">该公司：安全</div>
          </div>
          <div class="risk_prediction_right">
            <div class="risk_prediction_right_div">
              <div class="risk_prediction_right_div_top flex_center_between_start_box">
                <div class="risk_prediction_con_left">
                  <div class="risk_prediction_con_tit flex_center">
                    <div class="ztidax">抵押出质</div>
                    <div class="risk_prediction_rate flex_wrap">
                      <div v-for="(item,index) in 5" :key="index" :class="itemVlaue1>index?'iconfont icon-xingxing':'iconfont icon-xingxing1'"></div>
                    </div>
                  </div>
                </div>
                <div class="risk_prediction_con_left">
                  <div class="risk_prediction_con_tit flex_center">
                    <div class="ztidax">销售异常</div>
                    <div class="risk_prediction_rate flex_wrap">
                      <div v-for="(item,index) in 5" :key="index" :class="itemVlaue2>index?'iconfont icon-xingxing':'iconfont icon-xingxing1'"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="risk_prediction_right_div_bottom flex_center_between_start_box">
                <div class="risk_prediction_con_left">
                  <div class="risk_prediction_con_tit flex_center">
                    <div class="ztidax">客户集中度</div>
                    <div class="risk_prediction_rate flex_wrap">
                      <div v-for="(item,index) in 5" :key="index" :class="itemVlaue3>index?'iconfont icon-xingxing':'iconfont icon-xingxing1'"></div>
                    </div>
                  </div>
                </div>
                <div class="risk_prediction_con_left">
                  <div class="risk_prediction_con_tit flex_center">
                    <div class="ztidax">偿债能力</div>
                    <div class="risk_prediction_rate flex_wrap">
                      <div v-for="(item,index) in 5" :key="index" :class="itemVlaue4>index?'iconfont icon-xingxing':'iconfont icon-xingxing1'"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="risk_prediction">
        <div class="risk_prediction_tit">竞争力指数</div>
        <div class="flex_center_start_box">
          <div class="risk_prediction_left flex_center_col">
            <div ref="risk6" class="corporateRateLeftEcharts" />
            <div class="risk_prediction_left_tit">该公司：竞争力弱</div>
          </div>
          <div class="risk_prediction_right">
            <div class="risk_prediction_right_div">
              <div class="risk_prediction_right_div_top flex_center_between_start_box">
                <div class="risk_prediction_con_left">
                  <div class="risk_prediction_con_tit flex_center">
                    <div class="ztidax">销售质量</div>
                    <div class="risk_prediction_rate flex_wrap">
                      <div v-for="(item,index) in 5" :key="index" :class="itemValueA>index?'iconfont icon-xingxing':'iconfont icon-xingxing1'"></div>
                    </div>
                  </div>
                  <div>
                    <div class="risk_prediction_con_con flex_center_between_box">
                      <div>相对市占率:</div>
                      <div class="risk_prediction_center">{{marketValue}}%</div>
                      <div>行业前{{industy_marketValue}}%</div>
                    </div>
                    <div class="risk_prediction_con_con flex_center_between_box">
                      <div>销售增长率:</div>
                      <div class="risk_prediction_center">{{growth}}%</div>
                      <div>行业前{{industy_growth}}%</div>
                    </div>
                  </div>
                </div>
                <div class="risk_prediction_con_left">
                  <div class="risk_prediction_con_tit flex_center">
                    <div class="ztidax">研发创新</div>
                    <div class="risk_prediction_rate flex_wrap">
                      <div v-for="(item,index) in 5" :key="index" :class="itemValueB>index?'iconfont icon-xingxing':'iconfont icon-xingxing1'"></div>
                    </div>
                  </div>
                  <div>
                    <div class="risk_prediction_con_con flex_center_between_box">
                      <div>高新企业:</div>
                      <div>{{highCompany}}</div>
                    </div>
                    <div class="risk_prediction_con_con flex_center_between_box">
                      <div>专利数量:</div>
                      <div>{{highCompanyCount}}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="risk_prediction_right_div_bottom flex_center_between_start_box">
                <div class="risk_prediction_con_left">
                  <div class="risk_prediction_con_tit flex_center">
                    <div class="ztidax">行业地位</div>
                    <div class="risk_prediction_rate flex_wrap">
                      <div v-for="(item,index) in 5" :key="index" :class="itemValueC>index?'iconfont icon-xingxing':'iconfont icon-xingxing1'"></div>
                    </div>
                  </div>
                  <div>
                    <div class="risk_prediction_con_con flex_center_between_box">
                      <div>营业收入:</div>
                      <div class="risk_prediction_center">{{business | NumFormat}}</div>
                      <div>行业前{{businessValue}}%</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="risk_prediction">
        <div class="risk_prediction_tit">风控指数</div>
        <div class="flex_center_start_box">
          <div class="risk_prediction_left flex_center_col">
            <div ref="risk7" class="corporateRateLeftEcharts" />
            <div class="risk_prediction_left_tit">该公司：安全</div>
          </div>
          <div class="risk_prediction_right">
            <div class="risk_prediction_right_div">
              <div class="risk_prediction_right_div_top flex_center_between_start_box">
                <div class="risk_prediction_con_left">
                  <div class="risk_prediction_con_tit flex_center">
                    <div class="ztidax">严重违法失信</div>
                    <div class="risk_prediction_rate flex_wrap">
                      <div v-for="(item,index) in 5" :key="index" :class="itemVlaueD>index?'iconfont icon-xingxing':'iconfont icon-xingxing1'"></div>
                    </div>
                  </div>
                  <div>
                    <div class="risk_prediction_con_con flex_center_between_box">
                      <div>近三年数量:</div>
                      <div>{{riskFrom.illegalAndUntrustworthy}}</div>
                    </div>
                  </div>
                </div>
                <div class="risk_prediction_con_left">
                  <div class="risk_prediction_con_tit flex_center">
                    <div class="ztidax">限制高消费</div>
                    <div class="risk_prediction_rate flex_wrap">
                      <div v-for="(item,index) in 5" :key="index" :class="itemVlaueE>index?'iconfont icon-xingxing':'iconfont icon-xingxing1'"></div>
                    </div>
                  </div>
                  <div>
                    <div class="risk_prediction_con_con flex_center_between_box">
                      <div>近三年数量:</div>
                      <div>{{riskFrom.highSpendingLimits}}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="risk_prediction_right_div_bottom flex_center_between_start_box">
                <div class="risk_prediction_con_left">
                  <div class="risk_prediction_con_tit flex_center">
                    <div class="ztidax">行政处罚</div>
                    <div class="risk_prediction_rate flex_wrap">
                      <div v-for="(item,index) in 5" :key="index" :class="itemVlaueF>index?'iconfont icon-xingxing':'iconfont icon-xingxing1'"></div>
                    </div>
                  </div>
                  <div>
                    <div class="risk_prediction_con_con flex_center_between_box">
                      <div>近三年数量:</div>
                      <div>{{riskFrom.administrativePenalties}}</div>
                    </div>
                  </div>
                </div>
                <div class="risk_prediction_con_left">
                  <div class="risk_prediction_con_tit flex_center">
                    <div class="ztidax">失信人</div>
                    <div class="risk_prediction_rate flex_wrap">
                      <div v-for="(item,index) in 5" :key="index" :class="itemVlaueG>index?'iconfont icon-xingxing':'iconfont icon-xingxing1'"></div>
                    </div>
                  </div>
                  <div>
                    <div class="risk_prediction_con_con flex_center_between_box">
                      <div>近三年数量:</div>
                      <div>{{riskFrom.dishonestPerson}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="risk_prediction">
        <div class="risk_prediction_tit">供应链指数</div>
        <div class="flex_center_start_box">
          <div class="risk_prediction_left flex_center_col">
            <div ref="risk8" class="corporateRateLeftEcharts" />
            <div class="risk_prediction_left_tit">该公司：弱</div>
          </div>
          <div class="risk_prediction_right">
            <div class="risk_prediction_right_div">
              <div class="risk_prediction_right_div_top flex_center_between_start_box">
                <div class="risk_prediction_con_left">
                  <div class="risk_prediction_con_tit flex_center">
                    <div class="ztidax">议价能力</div>
                    <div class="risk_prediction_rate flex_wrap">
                      <div v-for="(item,index) in 5" :key="index" :class="itemValueH>index?'iconfont icon-xingxing':'iconfont icon-xingxing1'"></div>
                    </div>
                  </div>
                  <div>
                  </div>
                </div>
                <div class="risk_prediction_con_left">
                  <div class="risk_prediction_con_tit flex_center">
                    <div class="ztidax">客户质量</div>
                    <div class="risk_prediction_rate flex_wrap">
                      <div v-for="(item,index) in 5" :key="index" :class="itemValueJ>index?'iconfont icon-xingxing':'iconfont icon-xingxing1'"></div>
                    </div>
                  </div>
                  <div>
                    <div class="risk_prediction_con_con flex_center_between_box">
                      <div>高质量客户业务量占比:</div>
                      <div>40%</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="risk_prediction">
        <div class="risk_prediction_tit">ESG指数</div>
        <div class="flex_center_start_box">
          <div class="risk_prediction_left flex_center_col">
            <div ref="risk9" class="corporateRateLeftEcharts" />
            <div class="risk_prediction_left_tit">该公司：企业ESG管理水平一般</div>
          </div>
          <div class="risk_prediction_right">
            <div class="risk_prediction_right_div">
              <div class="risk_prediction_right_div_top flex_center_between_start_box">
                <div class="risk_prediction_con_left">
                  <div class="risk_prediction_con_tit flex_center">
                    <div class="ztidax">Environment，环境</div>
                    <div class="risk_prediction_rate flex_wrap">
                      <div v-for="(item,index) in 5" :key="index" :class="riskFrom.environment>index?'iconfont icon-xingxing':'iconfont icon-xingxing1'"></div>
                    </div>
                  </div>
                  <div>
                  </div>
                </div>
                <div class="risk_prediction_con_left">
                  <div class="risk_prediction_con_tit flex_center">
                    <div class="ztidax">Social，社会</div>
                    <div class="risk_prediction_rate flex_wrap">
                      <div v-for="(item,index) in 5" :key="index" :class="riskFrom.social>index?'iconfont icon-xingxing':'iconfont icon-xingxing1'"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="risk_prediction_right_div_bottom flex_center_between_start_box">
                <div class="risk_prediction_con_left">
                  <div class="risk_prediction_con_tit flex_center">
                    <div class="ztidax">Governance，公司治理</div>
                    <div class="risk_prediction_rate flex_wrap">
                      <div v-for="(item,index) in 5" :key="index" :class="riskFrom.governance>index?'iconfont icon-xingxing':'iconfont icon-xingxing1'"></div>
                    </div>
                  </div>
                </div>
                <div class="risk_prediction_con_left">
                  <div class="risk_prediction_con_tit flex_center">
                    <div class="ztidax">第三方ESG评估</div>
                    <div class="risk_prediction_rate flex_wrap">
                      <div v-for="(item,index) in 5" :key="index" :class="riskFrom.thirdEsg>index?'iconfont icon-xingxing':'iconfont icon-xingxing1'"></div>
                    </div>
                  </div>
                  <div>
                    <div class="risk_prediction_con_con flex_center_between_box">
                      <div>商道融绿:</div>
                      <div>{{ riskFrom.scoreEsgSyntaogf }}</div>
                    </div>
                    <div class="risk_prediction_con_con flex_center_between_box">
                      <div>华证指数:</div>
                      <div>{{ riskFrom.scoreEsgChindices }}</div>
                    </div>
                    <div class="risk_prediction_con_con flex_center_between_box">
                      <div>富时罗素:</div>
                      <div>{{ riskFrom.scoreEsgFtserussell }}</div>
                    </div>
                    <div class="risk_prediction_con_con flex_center_between_box">
                      <div>OWL:</div>
                      <div>暂无</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { creditInfo, operating, riskInfo, ratingInfo, getUserInfoCredit, indexTool } from "@/api/supplier.js";
import { mixins1 } from "@/mixins/index";
export default {
  mixins: [mixins1],
  data() {
    return {
      index: 1,
      Alldate: {},
      Alldate1: {},
      Alldate2: {},
      Alldate3: {},
      img: require('@/assets/img/system/supplier/workicon1.png'),
      img1: require('@/assets/img/system/supplier/wicon1.png'),
      img2: require('@/assets/img/system/supplier/wicon2.png'),
      img3: require('@/assets/img/system/supplier/wicon3.png'),
      // img4: require('@/assets/img/system/supplier/company1.png'),
      e_3Aecharts: {
        score: '90',
        score1: '5',
        score2: '45'
      },
      echartRisk1: null,
      percent: {
      },
      cnCompany: {},

      fontSize: '',
      echartWidth: '',
      minSize: '',
      raduisWidth: '',
      minRaduisWidth: '',
      addressInfo: '',
      serviceDiv: true,
      contractPhone: '',
      emails: '',
      webstate: '',
      revenueRecord: '',
      socialRecord: '',
      governanceStructure: '',
      management: '',
      shareholder: '',
      legalRepresentative: '',
      personnel_ex: null,
      management_ex: null,
      itemVlaue1: null,
      itemVlaue2: null,
      itemVlaue3: null,
      itemVlaue4: null,
      marketValue: "",
      industy_marketValue: '',
      industy_growth: '',
      growth: '',
      highCompanyCount: '',
      highCompany: '',
      business: '',
      businessValue: '',
      itemValueA: null,
      itemValueB: null,
      itemValueC: null,
      riskFrom: {},
      itemVlaueD: null,
      itemVlaueE: null,
      itemVlaueF: null,
      itemVlaueG: null,
      itemValueH: null,
      itemValueJ: null,
      serviceDiv1: false,
      companyName: '',
      logoPhoto: true,
      img4: ''
    }
  },
  created() {
    this.resizeFn()

  },
  methods: {
    async getCompany(name) {
      console.log(name)
      this.companyName = name
      let nameList = {}
      nameList.entname = name
      this.percent.campany = name
      let data = await creditInfo(nameList)
      this.Alldate = data.data
      this.Alldate1 = data.data.operatingResponse
      // data = await operating(nameList)
      // this.Alldate1 = data.data
      data = await riskInfo(nameList)
      this.Alldate2 = data.data
      data = await ratingInfo(nameList)
      this.e_3Aecharts = data.data
      data = await getUserInfoCredit('financingName=' + name)
      this.percent = data.data
      this.img4 = this.jpg + data.data.headPort
      if (data.data.city == null) {
        this.addressInfo = '--'
      }
      else if (data.data.city == '') {
        data.data.city = data.data.province
        this.addressInfo = data.data.city + ',' + data.data.province + ',' + data.data.country
      }
      else {
        this.addressInfo = data.data.city + ',' + data.data.province + ',' + data.data.country
      }
      if (data.data.headPort == null) {
        this.logoPhoto = false
      } else {
        this.logoPhoto = true
      }
      this.$nextTick(function () {
        this.riskEchart1(this.fontSize, this.echartWidth, this.minSize, this.raduisWidth, this.minRaduisWidth)
        this.riskEchart2(this.fontSize, this.echartWidth, this.minSize, this.raduisWidth, this.minRaduisWidth)
        this.riskEchart3(this.fontSize, this.echartWidth, this.minSize, this.raduisWidth, this.minRaduisWidth)
      })
    },
    async indexToolList() {
      console.log(this.companyName)
      let res = await indexTool('entName=' + this.companyName)
      if (res.data.regUnusualAbnormality) {
        this.address_ex = Math.ceil(res.data.regUnusualAbnormality / 20)
      }
      if (res.data.personUnusualAbnormality) {
        this.personnel_ex = Math.ceil(res.data.personUnusualAbnormality / 20)
      }
      if (res.data.operateAbnormality) {
        this.management_ex = Math.ceil(res.data.operateAbnormality / 20)
      }
      if (res.data.mortgagePledge) {
        this.itemVlaue1 = Math.ceil(res.data.mortgagePledge / 20)
      }
      if (res.data.salesAbnormality) {
        this.itemVlaue2 = Math.ceil(res.data.salesAbnormality / 20)
      }
      if (res.data.customerConcentration) {
        this.itemVlaue3 = Math.ceil(res.data.customerConcentration / 20)
      }
      if (res.data.solvency) {
        this.itemVlaue4 = Math.ceil(res.data.solvency / 20)
      }
      if (res.data.marketShare) {
        this.marketValue = Number(res.data.marketShare).toFixed(2)
      }
      if (res.data.marketShareRank) {
        this.industy_marketValue = Math.ceil(Number(res.data.marketShareRank / res.data.industryCompanyNum * 100))
      }
      if (res.data.growthRate) {
        this.growth = Number(res.data.growthRate).toFixed(2)
      }
      if (res.data.marketShareRank) {
        this.industy_marketValue = Math.ceil(Number(res.data.marketShareRank / res.data.industryCompanyNum * 100))
      }
      if (res.data.hightechEnterprise === 100) {
        this.highCompany = '是'
      } else if (res.data.hightechEnterprise === 0) {
        this.highCompany = '否'
      } else {
        return ''
      }
      if (res.data.totaloperatereveRank) {
        this.businessValue = Math.ceil(Number(res.data.totaloperatereveRank / res.data.industryCompanyNum * 100))
      }
      if (res.data.salesQuality) {
        this.itemValueA = Math.ceil(res.data.salesQuality / 20)
      }
      if (res.data.researchAndInnovation) {
        this.itemValueB = Math.ceil(res.data.researchAndInnovation / 20)
      }
      if (res.data.rankInTheField) {
        this.itemValueC = Math.ceil(res.data.rankInTheField / 20)
      }
      if (res.data.illegalAndUntrustworthy) {
        this.itemVlaueD = Math.ceil(res.data.illegalAndUntrustworthy / 20)
      }
      if (res.data.highSpendingLimits) {
        this.itemVlaueE = Math.ceil(res.data.highSpendingLimits / 20)
      }
      if (res.data.dishonestPerson) {
        this.itemVlaueF = Math.ceil(res.data.dishonestPerson / 20)
      }
      if (res.data.administrativePenalties) {
        this.itemVlaueG = Math.ceil(res.data.administrativePenalties / 20)
      }
      if (res.data.bargainingPower) {
        this.itemValueH = Math.ceil(res.data.bargainingPower / 20)
      }
      if (res.data.customerQuality) {
        this.itemValueJ = Math.ceil(res.data.customerQuality / 20)
      }
      this.riskFrom = res.data
      this.business = res.data.taking
      this.highCompanyCount = res.data.patent
      this.contractPhone = res.data.phone
      this.emails = res.data.email
      this.webstate = res.data.website
      this.revenueRecord = res.data.salesRecord
      this.socialRecord = res.data.paymentRecords
      this.governanceStructure = res.data.governanceStructure
      this.management = res.data.abnormalOperation
      this.shareholder = res.data.shareholdersAbnormal
      this.legalRepresentative = res.data.legalRepresentative
    },
    RiskPrediction() {
      this.serviceDiv = false
      this.serviceDiv1 = true
      this.$nextTick(function () {
        let j = document.getElementsByClassName('risk_prediction')
        for (let i = 0; i < j.length; i++) {
          j[i].getElementsByClassName('risk_prediction_left')[0].style.height = j[i].getElementsByClassName('risk_prediction_right')[0].clientHeight + 'px'
        }
        this.riskEchart4()
        this.riskEchart5()
        this.riskEchart6()
        this.riskEchart7()
        this.riskEchart8()
        this.riskEchart9()
        this.riskEchart1()
        this.riskEchart2()
        this.riskEchart3()
      })
      this.indexToolList()
    },
    returnButtonIcon() {
      this.serviceDiv = true
      this.serviceDiv1 = false
      this.$nextTick(() => {
        this.riskEchart1(this.fontSize, this.echartWidth, this.minSize, this.raduisWidth, this.minRaduisWidth)
        this.riskEchart2(this.fontSize, this.echartWidth, this.minSize, this.raduisWidth, this.minRaduisWidth)
        this.riskEchart3(this.fontSize, this.echartWidth, this.minSize, this.raduisWidth, this.minRaduisWidth)
      })
    },
    riskEchart1(fontSize, echartWidth, minSize, raduisWidth, minRaduisWidth) {
      let score = 0
      if (this.e_3Aecharts.dataQuality == '--') {
        score = 0
      } else {
        score = this.e_3Aecharts.dataQuality
      }
      if (this.echartRisk1 && this.echartRisk1 != null) {
        this.echartRisk1.dispose()
      }
      this.echartRisk1 = this.$echarts.init(this.$refs.risk1)
      const option = {
        series: [
          {
            type: 'gauge',
            startAngle: 180, // 开始角度 左侧角度
            endAngle: 0, // 结束角度 右侧
            min: 0,
            max: 100,
            radius: '180%',
            center: ['50%', '93%'],
            detail: {
              // formatter: score > 0 ? score : '--',
              formatter: this.e_3Aecharts.dataQuality=='--' ? this.Alldate.dataQuality : this.e_3Aecharts.dataQuality,
              fontSize: fontSize,
              color: '#142746',
              offsetCenter: ['0', '-32%']
            },
            title: {
              fontSize: minSize,
              color: '#142746',
              offsetCenter: ['0', '-3%']
            },
            axisLine: {
              lineStyle: {
                width: echartWidth,
                color: [
                  [0.20, '#EB2323'],
                  [0.40, '#F58F23'],
                  [0.60, '#FEDE29'],
                  [0.80, '#88C647'],
                  [1, '#148547']
                ]
              },
              roundCap: true
            },
            splitLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false
            },
            pointer: {
              show: true,
              icon: 'circle',
              height: '10%',
              width: raduisWidth,
              offsetCenter: [0, '-66%'],
              itemStyle: {
                color: 'auto'
              }
            },
            data: [
              {
                value: score,
                name: '数据质量分'
              }
            ]
          },
          {
            type: 'gauge',
            startAngle: 180, // 开始角度 左侧角度
            endAngle: 0, // 结束角度 右侧
            min: 0,
            max: 100,
            radius: '180%',
            center: ['50%', '93%'],
            detail: {
              show: false
            },
            title: {
              show: false
            },
            axisLine: {
              show: false
            },
            splitLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false
            },
            pointer: {
              show: true,
              icon: 'circle',
              height: '20%',
              width: minRaduisWidth,
              offsetCenter: [0, '-66%'],
              itemStyle: {
                color: '#fff'
              }
            },
            data: [
              {
                value: score
              }
            ]
          }
        ]
      }
      this.echartRisk1.setOption(option, true)
      this.echartRisk1.resize()
    },
    riskEchart2(fontSize, echartWidth, minSize, raduisWidth, minRaduisWidth) {
      let score = 0
      if (this.e_3Aecharts.aaaRating != '0') {
        score = this.e_3Aecharts.aaaRating.substr(2)
      }
      if (this.echartRisk1 && this.echartRisk11 != null) {
        this.echartRisk1.dispose()
      }
      this.echartRisk1 = this.$echarts.init(this.$refs.risk2)
      const option = {
        series: [
          {
            type: 'gauge',
            startAngle: 180, // 开始角度 左侧角度
            endAngle: 0, // 结束角度 右侧
            min: 19,
            max: 0,
            radius: '180%',
            center: ['50%', '93%'],
            detail: {
              formatter: score > 0 ? '3A' + score : '--',
              fontSize: fontSize,
              color: '#142746',
              offsetCenter: ['0', '-32%']
            },
            title: {
              fontSize: minSize,
              color: '#142746',
              offsetCenter: ['0', '-3%']
            },
            axisLine: {
              lineStyle: {
                width: echartWidth,
                color: [
                  [0.16, '#EB2323'],
                  [0.33, '#F58F23'],
                  [0.50, '#FEDE29'],
                  [0.66, '#88C647'],
                  [0.83, '#02B152'],
                  [1, '#148547']
                ]
              },
              roundCap: true
            },
            splitLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false
            },
            pointer: {
              show: true,
              icon: 'circle',
              height: '10%',
              width: raduisWidth,
              offsetCenter: [0, '-66%'],
              itemStyle: {
                color: 'auto'
              }
            },
            data: [
              {
                value: score,
                name: '3ARating评级'
              }
            ]
          },
          {
            type: 'gauge',
            startAngle: 180, // 开始角度 左侧角度
            endAngle: 0, // 结束角度 右侧
            min: 19,
            max: 0,
            radius: '180%',
            center: ['50%', '93%'],
            detail: {
              show: false
            },
            title: {
              show: false
            },
            axisLine: {
              show: false
            },
            splitLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false
            },
            pointer: {
              show: true,
              icon: 'circle',
              height: '20%',
              width: minRaduisWidth,
              offsetCenter: [0, '-66%'],
              itemStyle: {
                color: '#fff'
              }
            },
            data: [
              {
                value: score
              }
            ]
          }
        ]
      }
      this.echartRisk1.setOption(option, true)
      this.echartRisk1.resize()
    },
    riskEchart3(fontSize, echartWidth, minSize, raduisWidth, minRaduisWidth) {
      let score = 0
      let cc = { '0': 'AAA', '30': 'BBB', '40': 'BB-', '45': 'BBB-', '60': 'ccc' }
      if (this.e_3Aecharts.aaaRating != '0') {
        score = this.e_3Aecharts.aaaRating.substr(2)
      }
      if (this.echartRisk3 && this.echartRisk3 != null) {
        this.echartRisk3.dispose()
      }
      this.echartRisk3 = this.$echarts.init(this.$refs.risk3)
      const option = {
        series: [
          {
            type: 'gauge',
            startAngle: 180, // 开始角度 左侧角度
            endAngle: 0, // 结束角度 右侧
            min: 19,
            max: 0,
            radius: '180%',
            center: ['50%', '93%'],
            detail: {
              formatter: this.e_3Aecharts.thridRating,
              fontSize: fontSize,
              color: '#142746',
              offsetCenter: ['0', '-32%']
            },
            title: {
              fontSize: minSize,
              color: '#142746',
              offsetCenter: ['0', '-3%']
            },
            axisLine: {
              lineStyle: {
                width: echartWidth,
                color: [
                  [0.14, '#700608'],
                  [0.28, '#EB2323'],
                  [0.42, '#F58F23'],
                  [0.57, '#FEDE29'],
                  [0.71, '#88C647'],
                  [0.85, '#02B152'],
                  [1, '#148547']
                ]
              },
              roundCap: true
            },
            splitLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false
            },
            pointer: {
              show: true,
              icon: 'circle',
              height: '10%',
              width: raduisWidth,
              offsetCenter: [0, '-66%'],
              itemStyle: {
                color: 'auto'
              }
            },
            data: [
              {
                value: score,
                name: '第三方评级'
              }
            ]
          },
          {
            type: 'gauge',
            startAngle: 180, // 开始角度 左侧角度
            endAngle: 0, // 结束角度 右侧
            min: 19,
            max: 0,
            radius: '180%',
            center: ['50%', '93%'],
            detail: {
              show: false
            },
            title: {
              show: false
            },
            axisLine: {
              show: false
            },
            splitLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false
            },
            pointer: {
              show: true,
              icon: 'circle',
              height: '20%',
              width: minRaduisWidth,
              offsetCenter: [0, '-66%'],
              itemStyle: {
                color: '#fff'
              }
            },
            data: [
              {
                value: score
              }
            ]
          }
        ]
      }
      this.echartRisk3.setOption(option, true)
      this.echartRisk3.resize()
    },
    async riskEchart4() {
      let res = await indexTool('entName=' + this.companyName)
      let score = res.data.emptyShellIndex
      score = score.toFixed(1)
      this.echartRisk4 = this.$echarts.init(this.$refs.risk4)
      this.riskEchartAll(this.fontSize, this.echartWidth, this.minSize, this.raduisWidth, this.minRaduisWidth, score)
    },
    async riskEchart5() {
      let res = await indexTool('entName=' + this.companyName)
      let score = res.data.thunderIndex
      this.echartRisk4 = this.$echarts.init(this.$refs.risk5)
      this.riskEchartAll(this.fontSize, this.echartWidth, this.minSize, this.raduisWidth, this.minRaduisWidth, score)
    },
    async riskEchart6() {
      let res = await indexTool('entName=' + this.companyName)
      let score = res.data.competitivenessIndex
      this.echartRisk4 = this.$echarts.init(this.$refs.risk6)
      this.riskEchartAll(this.fontSize, this.echartWidth, this.minSize, this.raduisWidth, this.minRaduisWidth, score)
    },
    async riskEchart7() {
      let res = await indexTool('entName=' + this.companyName)
      let score = res.data.riskControlIndex
      this.echartRisk4 = this.$echarts.init(this.$refs.risk7)
      this.riskEchartAll(this.fontSize, this.echartWidth, this.minSize, this.raduisWidth, this.minRaduisWidth, score)
    },
    async riskEchart8() {
      let res = await indexTool('entName=' + this.companyName)
      let score = res.data.supplyChainIndex
      this.echartRisk4 = this.$echarts.init(this.$refs.risk8)
      this.riskEchartAll(this.fontSize, this.echartWidth, this.minSize, this.raduisWidth, this.minRaduisWidth, score)
    },
    async riskEchart9() {
      let res = await indexTool('entName=' + this.companyName)
      let score = res.data.esgIndex
      score = score.toFixed(1)
      this.echartRisk4 = this.$echarts.init(this.$refs.risk9)
      this.riskEchartAll(this.fontSize, this.echartWidth, this.minSize, this.raduisWidth, this.minRaduisWidth, score)
    },
    riskEchartAll(fontSize, echartWidth, minSize, raduisWidth, minRaduisWidth, score) {
      const option = {
        series: [
          {
            type: 'gauge',
            startAngle: 180, // 开始角度 左侧角度
            endAngle: 0, // 结束角度 右侧
            min: 0,
            max: 100,
            radius: '180%',
            center: ['50%', '93%'],
            detail: {
              formatter: score,
              fontSize: fontSize,
              color: '#142746',
              offsetCenter: ['0', '-32%']
            },
            title: {
              fontSize: minSize,
              color: '#142746',
              offsetCenter: ['0', '-3%']
            },
            axisLine: {
              lineStyle: {
                width: echartWidth,
                color: [
                  [0.50, '#88C647'],
                  [0.75, '#F58F23'],
                  [1, '#EB2323'],
                ]
              },
              roundCap: true
            },
            splitLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false
            },
            pointer: {
              show: true,
              icon: 'circle',
              height: '10%',
              width: raduisWidth,
              offsetCenter: [0, '-66%'],
              itemStyle: {
                color: 'auto'
              }
            },
            data: [
              {
                value: score,
                name: '指数(分)'
              }
            ]
          },
          {
            type: 'gauge',
            startAngle: 180, // 开始角度 左侧角度
            endAngle: 0, // 结束角度 右侧
            min: 0,
            max: 100,
            radius: '180%',
            center: ['50%', '93%'],
            detail: {
              show: false
            },
            title: {
              show: false
            },
            axisLine: {
              show: false
            },
            splitLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false
            },
            pointer: {
              show: true,
              icon: 'circle',
              height: '20%',
              width: minRaduisWidth,
              offsetCenter: [0, '-66%'],
              itemStyle: {
                color: '#fff'
              }
            },
            data: [
              {
                value: score
              }
            ]
          }
        ]
      }

      this.echartRisk4.setOption(option, true)
      this.echartRisk4.resize()
    },
    resizeFn() {
      this.$nextTick(() => {
        if (document.body.clientWidth > 1664) {
          this.fontSize = 51.9
          this.echartWidth = 8
          this.minSize = 11.9
          this.raduisWidth = 16
          this.minRaduisWidth = 7
        } else (
          this.fontSize = 34.1,
          this.echartWidth = 5,
          this.minSize = 8.9,
          this.raduisWidth = 10,
          this.minRaduisWidth = 4
        )
      })
    },
    returnButton() {
      location.reload()
    }
  }
}
</script>
<style scoped>
  /* .second_return_icon{
        display: none !important;
      } */
  .risk_prediction {
    width: 100%;
    height: auto;
    margin-bottom: 40px;
    padding: 10px 30px 30px 30px;
    background: #ffffff;
    box-shadow: 0px 10px 20px 0px rgba(12, 79, 71, 0.15);
    border-radius: 10px;
  }
  .second_title_box {
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .second_title1 {
    font-size: 18px;
    font-family: MicrosoftYaHei;
    color: #162747;
  }
  .work_business_company_div {
    line-height: 86px !important;
    padding: 0 !important;
    margin: 0 !important;
  }
  .companyDiv {
    padding-left: 12px;
    padding-right: 15px;
  }
  .corporateRateLeftEcharts {
    width: 230px;
    height: 120px;
  }
  .corporateRateLeftEcharts1 {
    width: 230px;
    height: 120px;
  }
  .work_top {
    padding-bottom: 30px;
  }
  .work_top_div {
    width: 86px;
    height: 86px;
    background: #ffffff;
    border-radius: 50%;
    box-shadow: 0px 2px 10px 0px rgba(12, 79, 71, 0.16);
    margin-right: 20px;
  }
  .work_top_div img {
    position: relative;
    top: 40%;
    left: 40%;
  }
  .over2 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .float_right {
    float: right;
  }
  .icon-gudongxinxi {
    font-size: 30px !important;
  }
  .icon-xiaopangchezhengxinguanli {
    font-size: 24px !important;
  }
  tr td {
    min-width: 90px;
    max-width: 90px;
    height: 38px;
    padding-top: 10px;
    font-size: 14px;
  }
  tr td:nth-child(1) {
    margin-right: 10px;
    color: #54657f;
    text-align: left;
  }
  tr td:nth-child(2) {
    text-align: right;
  }
  tr td:nth-child(3) {
    text-align: right;
  }
  .corporate_image_tit {
    margin-bottom: 20px;
    line-height: 20px;
    font-size: 16px;
    text-align: center;
  }
  .corporate_image {
    width: 1500px;
    height: 376px;
    padding-top: 64px;
    padding-bottom: 50px;
    overflow-x: scroll;
    overflow-y: hidden;
    overflow-x: hidden;
  }
  .corporate_image_div {
    width: auto;
  }
  .corporate_image_div table {
    overflow: hidden;
  }
  .corporate_image_icon {
    position: absolute;
    /* position: relative; */
    width: 60px;
    height: 60px;
    left: 50%;
    top: -30px;
    border-radius: 50%;
    transform: translateX(-50%);
    background-color: #339c9b;
    box-shadow: 0px 8px 14px 0px rgba(47, 160, 159, 0.23);
  }
  .corporate_image_edit {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 4px;
    height: 18px;
  }
  .corporate_image_edit div {
    position: relative;
    width: 4px;
    height: 4px;
    margin-bottom: 2px;
    border-radius: 50%;
    background: #54657f;
  }
  .corporate_image_icon .iconfont {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 21px;
  }
  .corporate_image li {
    position: relative;
    flex-shrink: 0;
    width: 225px;
    height: 262px;
    margin-left: 12px;
    margin-right: 12px;
    padding: 50px 20px 0 20px;
    background-color: #fff;
    border-radius: 14px;
    box-shadow: 0px 10px 14px 0px rgba(12, 79, 71, 0.16);
    -webkit-transition: all 0.1s;
    transition: all 0.1s;
  }
  .corporate_image li:hover {
    width: 400px;
  }
  .corporate_image li:nth-child(1):hover {
    margin-left: 183px;
  }
  .corporate_image li:nth-child(6):hover {
    margin-right: 183px;
  }
  .corporate_image li:hover tr td {
    min-width: 117px;
    max-width: 120px;
  }
  .corporate_image li:hover .table_line2 tr td:nth-child(1) {
    min-width: 100px;
    max-width: 100px;
  }
  .corporate_image li:hover .table_line2 tr td:nth-child(2) {
    min-width: 250px;
    max-width: 250px;
  }
  .corporate_image li:hover .corporate_image_edit {
    display: none;
  }
  .corporate_image li .corporate_image_tr_td3 {
    display: none;
  }
  .corporate_image li:hover .corporate_image_tr_td3 {
    display: table-cell;
  }
  .up {
    color: #339c9b;
  }
  .down {
    color: #fb6476;
  }
  .corporate_image li:nth-child(1) {
    margin-left: 7px;
  }
  .corporate_image li:last-child {
    margin-right: 7px;
  }
  .work_chart_detail_color.color1 {
    background-color: #68bf50;
  }
  .work_chart_detail_score.color1 {
    color: #68bf50;
  }
  .work_chart_detail_color.color2 {
    background-color: #f6c009;
  }
  .work_chart_detail_score.color2 {
    color: #f6c009;
  }
  .work_chart_detail_color.color3 {
    background-color: #f8e71c;
  }
  .work_chart_detail_score.color3 {
    color: #f8e71c;
  }
  .work_chart_detail_color.color4 {
    background-color: #ff6c64;
  }
  .work_chart_detail_score.color4 {
    color: #ff6c64;
  }
  .work_chart_detail_color.color5 {
    background-color: #d0021b;
  }
  .work_chart_detail_score.color5 {
    color: #d0021b;
  }
  .work_business {
    width: 650px;
    height: 410px;
    padding: 40px 40px 0 40px;
    background-size: 100% 100%;
    border-radius: 14px;
    background-image: url('../../../../assets/img/system/supplier/workbg.png');
    box-shadow: 0px 0px 18px 0px rgba(12, 79, 71, 0.19);
    /* background: linear-gradient(180deg, #EFFFFD 0%, #D2F2FF 100%); */
  }
  .work_business_company {
    line-height: 33px;
    font-size: 24px;
    color: #339c9b;
    margin-top: 8px;
    padding-bottom: 18px;
  }
  .work_business_company_en {
    line-height: 18px;
    font-size: 16px;
    color: #54657f;
  }
  .work_business_detail li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* height: 42px; */
    height: 60px;
  }
  .work_business_detail .iconfont {
    font-size: 21px;
    padding-right: 10px;
    color: #54657f;
  }
  .work_business_detail_font {
    font-size: 16px;
    line-height: 22px;
  }
  .work_business_detail_icon {
    width: 21px;
    height: 21px;
    margin-right: 10px;
    background-size: 100% 100%;
  }
  .min_width {
    min-width: 195px;
  }
  .work_business_person {
    margin-top: 50px;
  }
  .work_chart {
    width: 800px;
  }
  .work_chart_item img {
    width: 230px;
    height: 120px;
    background-size: 100% 100%;
  }
  .work_chart_detail {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .work_chart_detail li {
    width: 144px;
    height: 56px;
    border-radius: 10px;
    box-shadow: 0px 2px 10px 0px rgba(12, 79, 71, 0.16);
    background-color: #fff;
  }
  .work_chart_detail_color {
    width: 100%;
    height: 6px;
    margin-bottom: 4px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .work_chart_detail_score {
    line-height: 22px;
    font-size: 16px;
    text-align: center;
  }
  .work_chart_detail_tit {
    line-height: 18px;
    font-size: 12px;
    text-align: center;
    color: #54657f;
  }
  /* 风险预测 */

  .risk_prediction_tit {
    height: 42px;
    line-height: 42px;
    font-size: 18px;
  }

  .risk_prediction_left {
    /* height: 500px; */
    background: #f7fafc;
    border-radius: 12px;
    width: 19%;
    margin-right: 2%;
  }
  .risk_prediction_left_tit {
    height: 18px;
    margin-top: 30px;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
  }
  .risk_prediction_right {
    height: 100%;
    background: #f7fafc;
    border-radius: 12px;
    width: 79%;
  }
  .risk_prediction_right_div {
    padding: 30px 60px 0 60px;
    min-height: 278px;
  }
  .risk_prediction_right_div_top {
    margin-bottom: 60px;
  }
  .risk_prediction_right_div_bottom {
    margin-bottom: 30px;
  }
  .risk_prediction_con_left {
  }
  .risk_prediction_con_tit {
    width: 400px;
    height: 38px;
    background: #ffffff;
    border-radius: 19px;
  }
  .risk_prediction_con_con {
    line-height: 18px;
    padding: 0 20px;
    margin-top: 20px;
    font-size: 14px;
  }
  .risk_prediction_rate {
    margin-left: 20px;
  }
  .iconfont.icon-xingxing,
  .iconfont.icon-xingxing1 {
    color: #fb6476;
  }
  .risk_prediction_center {
    width: 110px;
    text-align: right;
  }
  .risk_prediction_con_con div {
    min-width: 80px;
  }
  .risk_prediction_con_con div:first-child {
    text-align: left;
  }
  .risk_prediction_con_con div:last-child {
    text-align: right;
  }
  .ztidax {
    font-size: 14px;
  }
  @media screen and (max-width: 1664px) {
    .ztidax {
      font-size: 12px;
    }
    .second_title_box {
      margin-top: 13px;
      margin-bottom: 20px;
    }
    .second_title1 {
      font-size: 12px;
      font-family: MicrosoftYaHei;
      color: #162747;
    }
    /* 风险预测 */
    .risk_prediction {
      width: 100%;
      height: auto;
      margin-bottom: 27px;
      padding: 7px 20px 20px 20px;
      background: #ffffff;
      box-shadow: 0px 7px 14px 0px rgba(12, 79, 71, 0.15);
      border-radius: 7px;
    }

    .risk_prediction_tit {
      height: 28px;
      line-height: 28px;
      font-size: 12px;
    }

    .risk_prediction_left {
      /* height: 500px; */
      background: #f7fafc;
      border-radius: 8px;
      width: 19%;
      margin-right: 2%;
    }
    .risk_prediction_left_tit {
      height: 12px;
      margin-top: 20px;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
    }
    .risk_prediction_right {
      height: 100%;
      background: #f7fafc;
      border-radius: 8px;
      width: 79%;
    }
    .risk_prediction_right_div {
      padding: 20px 40px 0 40px;
      min-height: 185px;
    }
    .risk_prediction_right_div_top {
      margin-bottom: 40px;
    }
    .risk_prediction_right_div_bottom {
      margin-bottom: 20px;
    }
    .risk_prediction_con_tit {
      width: 277px;
      height: 25px;
      background: #ffffff;
      border-radius: 13px;
    }
    .risk_prediction_con_con {
      line-height: 12px;
      padding: 0 14px;
      margin-top: 14px;
      font-size: 12px;
    }
    .risk_prediction_rate {
      margin-left: 14px;
    }
    .iconfont.icon-xingxing,
    .iconfont.icon-xingxing1 {
      color: #fb6476;
    }
    .risk_prediction_center {
      width: 73px;
      text-align: right;
    }
    .risk_prediction_con_con div {
      min-width: 53px;
    }
    .risk_prediction_con_con div:first-child {
      text-align: left;
    }
    .risk_prediction_con_con div:last-child {
      text-align: right;
    }
    .work_business_company_div {
      line-height: 57px !important;
    }
    .companyDiv {
      padding-left: 8px;
      padding-right: 9px;
    }
    .corporateRateLeftEcharts {
      width: 153px;
      height: 80px;
    }
    .corporateRateLeftEcharts1 {
      width: 153px;
      height: 80px;
    }
    .work_top {
      padding-bottom: 20px;
    }
    .work_top_div {
      width: 57px;
      height: 57px;
      background: #ffffff;
      border-radius: 50%;
      box-shadow: 0px 2px 7px 0px rgba(12, 79, 71, 0.16);
      margin-right: 14px;
    }
    .work_top_div img {
      position: relative;
      top: 35%;
      left: 36%;
    }
    .icon-gudongxinxi {
      font-size: 20px !important;
    }
    .icon-xiaopangchezhengxinguanli {
      font-size: 16px !important;
    }
    tr td {
      min-width: 60px;
      max-width: 60px;
      height: 25px;
      padding-top: 7px;
      font-size: 12px;
    }
    tr td:nth-child(1) {
      margin-right: 7px;
      color: #54657f;
      text-align: left;
    }
    .corporate_image_tit {
      margin-bottom: 13px;
      line-height: 13px;
      font-size: 12px;
      text-align: center;
    }
    .corporate_image {
      width: 1000px;
      height: 251px;
      padding-top: 43px;
      padding-bottom: 33px;
      overflow-x: scroll;
      overflow-y: hidden;
      overflow-x: hidden;
    }
    .corporate_image_div {
      width: auto;
    }
    .corporate_image_div table {
      overflow: hidden;
    }
    .corporate_image_icon {
      position: absolute;
      /* position: relative; */
      width: 40px;
      height: 40px;
      left: 50%;
      top: -20px;
      border-radius: 50%;
      transform: translateX(-50%);
      background-color: #339c9b;
      box-shadow: 0px 5px 9px 0px rgba(47, 160, 159, 0.23);
    }
    .corporate_image_edit {
      position: absolute;
      top: 11px;
      right: 11px;
      width: 3px;
      height: 12px;
    }
    .corporate_image_edit div {
      position: relative;
      width: 3px;
      height: 3px;
      margin-bottom: 2px;
      border-radius: 50%;
      background: #54657f;
    }
    .corporate_image_icon .iconfont {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      font-size: 14px;
    }
    .corporate_image li {
      position: relative;
      flex-shrink: 0;
      width: 150px;
      height: 175px;
      margin-left: 8px;
      margin-right: 8px;
      padding: 33px 13px 0 13px;
      background-color: #fff;
      border-radius: 9px;
      box-shadow: 0px 7px 9px 0px rgba(12, 79, 71, 0.16);
      -webkit-transition: all 0.1s;
      transition: all 0.1s;
    }
    .corporate_image li:hover {
      width: 267px;
    }
    .corporate_image li:nth-child(1):hover {
      margin-left: 122px;
    }
    .corporate_image li:nth-child(6):hover {
      margin-right: 122px;
    }
    .corporate_image li:hover tr td {
      min-width: 78px;
      max-width: 80px;
    }
    .corporate_image li:hover .table_line2 tr td:nth-child(1) {
      min-width: 67px;
      max-width: 67px;
    }
    .corporate_image li:hover .table_line2 tr td:nth-child(2) {
      min-width: 167px;
      max-width: 167px;
    }
    .corporate_image li:hover .corporate_image_edit {
      display: none;
    }
    .corporate_image li .corporate_image_tr_td3 {
      display: none;
    }
    .corporate_image li:hover .corporate_image_tr_td3 {
      display: table-cell;
    }
    .up {
      color: #339c9b;
    }
    .down {
      color: #fb6476;
    }
    .corporate_image li:nth-child(1) {
      margin-left: 5px;
    }
    .corporate_image li:last-child {
      margin-right: 5px;
    }
    .work_chart_detail_color.color1 {
      background-color: #68bf50;
    }
    .work_chart_detail_score.color1 {
      color: #68bf50;
    }
    .work_chart_detail_color.color2 {
      background-color: #f6c009;
    }
    .work_chart_detail_score.color2 {
      color: #f6c009;
    }
    .work_chart_detail_color.color3 {
      background-color: #f8e71c;
    }
    .work_chart_detail_score.color3 {
      color: #f8e71c;
    }
    .work_chart_detail_color.color4 {
      background-color: #ff6c64;
    }
    .work_chart_detail_score.color4 {
      color: #ff6c64;
    }
    .work_chart_detail_color.color5 {
      background-color: #d0021b;
    }
    .work_chart_detail_score.color5 {
      color: #d0021b;
    }
    .work_business {
      width: 433px;
      height: 273px;
      padding: 27px 27px 0 27px;
      background-size: 100% 100%;
      background-image: url('../../../../assets/img/system/supplier/workbg.png');
      box-shadow: 0px 0px 12px 0px rgba(12, 79, 71, 0.19);
      border-radius: 9px;
    }
    .work_business_company {
      line-height: 22px;
      font-size: 16px;
      color: #339c9b;
      margin-top: 5px;
      padding-bottom: 12px;
    }
    .work_business_company_en {
      line-height: 12px;
      font-size: 12px;
      color: #54657f;
    }
    .work_business_detail li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 40px;
    }
    .work_business_detail .iconfont {
      font-size: 14px;
      padding-right: 7px;
      color: #54657f;
    }
    .work_business_detail_font {
      font-size: 12px;
      line-height: 15px;
    }
    .work_business_detail_icon {
      width: 14px;
      height: 14px;
      margin-right: 7px;
      background-size: 100% 100%;
    }
    .min_width {
      min-width: 130px;
    }
    .work_business_person {
      margin-top: 33px;
    }
    .work_chart {
      width: 533px;
    }
    .work_chart_item img {
      width: 153px;
      height: 80px;
      background-size: 100% 100%;
    }
    .work_chart_detail {
      margin-top: 27px;
      margin-bottom: 27px;
    }
    .work_chart_detail li {
      width: 96px;
      height: 37px;
      border-radius: 7px;
      box-shadow: 0px 2px 7px 0px rgba(12, 79, 71, 0.16);
      background-color: #fff;
    }
    .work_chart_detail_color {
      width: 100%;
      height: 4px;
      margin-bottom: 3px;
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;
    }
    .work_chart_detail_score {
      line-height: 15px;
      font-size: 12px;
      text-align: center;
    }
    .work_chart_detail_tit {
      line-height: 12px;
      font-size: 12px;
      text-align: center;
      color: #54657f;
    }
  }
</style>